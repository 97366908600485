import React from 'react'
import styled from 'styled-components'

import PrimaryButton from '../../components/Buttons/PrimaryButton.js';

const BillOfLanding = styled.div`

.es-c-container--pdf {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 14px;
  margin-bottom: 200px;
  background-color: #e7edf0;
  // 📰
  display: grid;
  grid-template-rows: 0.3fr 0.4fr 3fr;
  grid-template-columns: 1fr 0.2fr 0.2fr;
  grid-template-areas:
    "sg_bill_title sg_download sg_print"
    "sg_img sg_img sg_img"
    "sg_img sg_img sg_img";

  .es-sg-bill-title {
    grid-area: sg_bill_title; // 🗺
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: bold;
    align-self: center;
    padding-top: 38px;
    padding-bottom: 20px;
    padding-left: 5px;
  }
  .es-sg-download {
    grid-area: sg_download; // 🗺
    align-self: center;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-self: end;
    button {
      background-color: transparent;
      border: transparent;
      text-transform: uppercase;
    }
  }
  .es-sg-print {
    grid-area: sg_print; // 🗺
    align-self: center;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-self: end;
  }
  .es-sg-img {
    grid-area: sg_img; // 🗺
    background-color: #fff;
    img{
      max-width: 100%;
  height: auto;
  background-size: cover;
    }


  }
}
`;

const EchoShipMiscComponent = () => {
  return (
    <BillOfLanding>
      <div className="es-c-container--pdf">
        <div className="es-sg-bill-title">
          <p>Shipment 2300045</p>
        </div>
        <div className="es-sg-download">
          <button
            className="es-c-btn btn-remove"
            style={{
              color: "#0033a1"
            }}
          >
            Download
          </button>
        </div>
        <div className="es-sg-print">
          <PrimaryButton>Print</PrimaryButton>
        </div>
        <div className="es-sg-img">
          <img src="https://res.cloudinary.com/dj1xukpnf/image/upload/v1551385414/pabst-echo.png" />
        </div>
      </div>
    </BillOfLanding>  
  )
}

export default EchoShipMiscComponent;
