import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import OrganismAddItemSummary from '../../../Organisms/OrganismAddItemSummary.js'
import BillOfLanding from '../../Misc/BillOfLanding.js'

const GContentBookConfirmation = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 1fr) 1fr 0.1fr;
  grid-template-columns: 1fr 0.5fr;
  grid-template-areas:
    "sg_add_items sg_summary"
    "sg_navigation_stepper sg_summary";

  .SGAddToShipment {
    grid-area: sg_add_items; // 🗺
  }
  .SGSummary {
    grid-area: sg_summary; // 🗺
  }
`;

const SGAddToShipment = styled.div`
  padding-right: 10px;
  border-right: 2px solid #f8f8f8;
  margin-top: 20px;
`;
const SGSummary = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
`;

const GComponentPageTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;  
`;
const PageTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;
`;




const GridComponentAddItems = () => {
  return (

    <GContentBookConfirmation>

        <SGAddToShipment>

            <GComponentPageTitle>
                <PageTitle>Bill of Landing</PageTitle>
            </GComponentPageTitle>
            
            <BillOfLanding></BillOfLanding>
          
        </SGAddToShipment>

        <SGSummary>
            <OrganismAddItemSummary></OrganismAddItemSummary>
        </SGSummary>



    </GContentBookConfirmation>

  )
}

export default GridComponentAddItems;